import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions';

const ErrorPage = ({ data, location }) => {
  return (
    <Layout pageTitle="404" pageClass="template-404" location={location}>
      <PageTransition>
        <div className="page-404 grid">
          <div className="page-404__content">
            <h1 className="page-404__title">404</h1>
            <h2 className="page-404__subtitle h3">Sorry, the page you're looking for doesn't exist.</h2>
            <p className="page-404__text">Head back over to our <Link to="/">homepage</Link> or <Link to="/contact">get in touch</Link> to let us know how we can help.</p>
          </div>
        </div>
      </PageTransition>
    </Layout>
  )
}

export default ErrorPage